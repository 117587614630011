import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'menu', // pantalla principal, nombre del path donde navegar
    pathMatch: 'full'
  },

 /* {
    path: 'web',
    loadChildren: () => import('../../web').then( m => m.index.html)
  },
*/
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },

  {
    path: 'conversacion',
    loadChildren: () => import('./pages/conversacion/conversacion.module').then( m => m.ConversacionPageModule)
  },
  {
    path: 'listadopreguntas',
    loadChildren: () => import('./pages/listadopreguntas/listadopreguntas.module').then( m => m.ListadopreguntasPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./pages/slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'tabshb',
    loadChildren: () => import('./pages/tabshb/tabshb.module').then( m => m.TabshbPageModule)
  },
  {
    path: 'bibliografia',
    loadChildren: () => import('./pages/bibliografia/bibliografia.module').then( m => m.BibliografiaPageModule)
  },
  {
    path: 'speech',
    loadChildren: () => import('./pages/speech/speech.module').then( m => m.SpeechPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
