import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Ipages } from '../interfaces/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor( private http: HttpClient ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // para obtener del json las opciones de menu
  // devuleve un array de opciones de menu para paginas
  getMenuOpts() {
    return this.http.get<Ipages[]>('/assets/data/menu-opts.json');
  }

  getPreguntas() {
    return this.http.get<any[]>('/assets/data/preguntas.json');
  }
  
}
